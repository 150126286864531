import { Flex, List, Modal, Tag, Typography, Button, Input } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { disableNotifyExpiredDocuments, getAlmostExpiredDocuments } from 'src/api/document.api';
import { debounce } from 'lodash';
import { showNotification } from 'src/utils/notification';
const { Text } = Typography;
export default function PrincipelExpiringModal({ open, setOpen }) {
  const [page, setPage] = useState(1);
  const [searchCondition, setSearchCondition] = useState('');
  const queryClient = useQueryClient();

  const almostExpiredDocumentQuery = useQuery({
    queryKey: ['almostExpiredDocument', searchCondition, page, true],
    queryFn: () => {
      const params = {
        searchCondition,
        page,
        isPrinciple: true
      };
      return getAlmostExpiredDocuments(params);
    },
    staleTime: 60 * 1000 * 60,
    keepPreviousData: true
  });

  const disableNotifyMutation = useMutation({
    mutationFn: (documentId) => disableNotifyExpiredDocuments(documentId),
    onSuccess: (_, documentId) => {
      setPage(1);
      handleInvalidQuery();
      showNotification(`Tắt thông báo hợp đồng ${documentId} thành công!`, 'success');
    }
  });

  const handleInvalidQuery = () => {
    queryClient.invalidateQueries(['almostExpiredDocument', searchCondition, page, true]);
    queryClient.invalidateQueries(['countAlmostExpired']);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const isExpired = (expirationDate) => {
    const now = new Date();
    const [day, month, year] = expirationDate.slice(0, 10).split('/');
    const expiredDate = new Date(year, month - 1, day);
    const result = expiredDate.getTime() < now.getTime();
    return result;
  };

  const debouncedSearch = useMemo(() => debounce((term) => setSearchCondition(term), 1000), []);

  return (
    <Modal
      className='principle-expiring-modal'
      open={open}
      title='Hợp đồng nguyên tắc sắp hết hạn'
      zIndex={1050}
      okText='Đồng ý'
      cancelText='Không'
      onCancel={handleCancel}
      footer={null}
    >
      <Flex gap={10} vertical align='start'>
        <Flex gap={10} align='baseline'>
          <Text>Tìm kiếm</Text>
          <Input.Search
            allowClear
            placeholder='ID, Số HĐ, Tên HĐ'
            className='filter-input'
            onSearch={(value) => setSearchCondition(value)}
            onChange={(e) => debouncedSearch(e.target.value)}
          ></Input.Search>
        </Flex>
        <List
          style={{
            width: '100%',
            height: '600px',
            overflow: 'auto',
            border: '1px solid rgba(140, 140, 140, 0.35)'
          }}
          loading={almostExpiredDocumentQuery.isFetching}
          bordered
          dataSource={almostExpiredDocumentQuery.data?.data?.listDocument}
          pagination={{
            onChange: (page) => setPage(page),

            pageSize: 8,
            total: almostExpiredDocumentQuery.data?.data?.total
          }}
          renderItem={(item) => (
            <List.Item
            // actions={[
            //   <Button
            //     type='primary'
            //     onClick={() => {
            //       // navigate(urlPath.createDocument.location, {
            //       //   state: {
            //       //     docId: item?.id,
            //       //     categoryId: item?.categoryId,
            //       //     editorPageType: editorPageType.RENEW_PRINCIPLE,
            //       //     documentPrincipleId: null,
            //       //     informations: null,
            //       //     isTemplate: false,
            //       //     isRenew: true
            //       //   }
            //       // });
            //       setOpen(false);
            //     }}
            //   >
            //     Copy thành bản mới
            //   </Button>
            // ]}
            >
              <List.Item.Meta
                title={<Link>{`ID: ${item.id} - ${item?.saveName || item?.title}`}</Link>}
                description={
                  <Flex vertical gap={10}>
                    <Text>{`Hiệu lực: ${item.effectiveDate.slice(0, 10)} - ${item.expirationDate.slice(0, 10)}`}</Text>

                    <Flex gap={10} justify='space-between'>
                      <Tag
                        style={{ width: 'fit-content', height: 'fit-content' }}
                        color={isExpired(item.expirationDate) ? '#f50' : '#b7ada8'}
                      >
                        {isExpired(item.expirationDate) ? 'Đã hết hạn' : 'Sắp hết hạn'}
                      </Tag>
                      <Button type='link' onClick={() => disableNotifyMutation.mutate(item?.id)}>
                        Tắt thông báo
                      </Button>
                    </Flex>
                  </Flex>
                }
              />
            </List.Item>
          )}
        />
      </Flex>
    </Modal>
  );
}
